.DateRangePicker {
    font-family: 'Montserrat', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.DateInput.DateInput_1 {
    width: 120px;
}

.DateRangePicker_picker_1 {
    z-index: 100;
}

.DateInput_input__focused {
    border-bottom: 2px solid #475ddc;
}

.CalendarDay__hovered_span {
    background-color: rgba(71, 93, 220, 0.25);
    border-color: rgba(71, 93, 220, 0.25);
    color: #000;
}

.CalendarDay__hovered_span:hover {
    background-color: rgba(71, 93, 220, 0.35);
    border-color: rgba(71, 93, 220, 0.35);
    color: #000;
}

.CalendarDay__selected_span {
    background: #94A1EF;
    border: 1px double #94A1EF;
    color: #fff;
}

.CalendarDay__selected_span:hover {
    background: #697AE5;
    border: 1px double #697AE5;
    color: #fff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #253DD2;
    border: 1px double #253DD2;
    color: #fff;
}

.CalendarDay__selected_start {
    background: #253DD2;
    border: 1px double #253DD2;
    color: #fff;
}

.CalendarMonth_table {
    margin-top: 5px;
}
