.data-grid-container .data-grid {
  font-size: 1.2em
}
.data-grid-container .data-grid .cell > input {
  border-radius: 0px;
  width: 100%; 
}
.data-grid-container .data-grid .cell  input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align: right;
  width: 100%;
  height: .8em;
  background: none;
  display: block;
  padding-right:4px;
}
.data-grid-container .data-grid .cell .value-viewer {
  padding-right:2px;
  overflow: hidden;
}
