
#googleLogin {
  width: 100%;
  border: none;
  margin-top: 10px;
  padding: 0;
  cursor: pointer;
}

#googleLogin > div {
  margin: 0 auto;
}

.abcRioButton {
  width: "100%";
}
