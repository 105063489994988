.custom-react-dates .SingleDatePicker {
  width: 100%;
}

.custom-react-dates .SingleDatePicker .SingleDatePickerInput {
  width: 100%;
}

.custom-react-dates .SingleDatePicker .SingleDatePickerInput .DateInput {
  width: 100%;
}

table.ui.table.experiments-table > tbody > tr:first-child {
  background: #f8ffff;
  color: #276f86;
  box-shadow: 0px 1px #a6dcec;
}
