/* SHARED */

.axis path,
.axis line {
    fill: none;
    stroke: grey;
    stroke-width: 1;
    shape-rendering: crispEdges;
}

/* PR PLOT & ROC PLOT */

.line {
    stroke-width: 1;
    fill: none;
}

.luck {
    stroke: red;
    stroke-width: 0.5;
    fill: none;
}

.mean {
    stroke: blue;
    stroke-width: 3;
    fill: none;
}

.upper, .lower {
    stroke: black;
    stroke-width: 0.5;
    fill: none;
}

.between {
  fill: #D3D3D3;
  opacity: 0.5;
}


/* PROBS PLOT */

.bar rect {
  shape-rendering: crispEdges;
}

.bar text {
  fill: #999999;
}

div.tooltip {
    position: absolute;
    text-align: center;
    width: 60px;
    height: 28px;
    padding: 2px;
    background: lightsteelblue;
    border: 0px;
    pointer-events: none;
    font: 10px sans-serif;
}

/* CDF PLOT */

.cdf {
    stroke: steelblue;
    fill: none;
}
